<template>
	<div>
		<Principal v-bind="principal"></Principal>
		<Features v-bind="feature"></Features>
		<Features v-bind="feature1"></Features>
	</div>
</template>

<script>
import Principal from '../components/Principal.vue';
import Features from '../components/Features.vue';

export default {
	components: {
		Principal,
		Features,
		Features,
	},

	data() {
		return {
			principal: {
				titulo_1: 'SERVICIOS',
				titulo_2: 'PRODUCTOS',
				imagen:
					'http://dimamsas.com/wp-content/uploads/2019/10/35357172_10214829880933420_841499335848886272_n.jpg',
				goto: '#features',
			},
			feature: {
				titulo: 'NUESTROS PRODUCTOS',
				features: [
					{
						icon: 'panorama_fish_eye',
						title: 'Agricultura',
						text: 'Sellos y kits de hidraulicos e industriales',
						color: 'success',
					},
					{
						icon: 'markunread_mailbox',
						title: 'Construccion',
						text: 'Bombas y Motores Hidraulicos para multiples aplicaciones',
						color: 'error',
					},
					{
						icon: 'toll',
						title: 'Camisas rectificadas',
						text: 'Camisas rectificadas internamente para aplicaciones hidraulicas',
						color: 'warning',
					},
					{
						icon: 'workspaces',
						title: 'Barras Cromadas',
						text: 'Venta de barras cromadas y aplicacion de cromoduro',
						color: '#FF8733',
					},
				],
			},
			feature1: {
				titulo: 'NUESTROS SERVICIOS',
				features: [
					{
						icon: 'build',
						title: 'Mantenimiento',
						text: 'Mantenimiento de Cilindros Motores, Bombas y Controles Hidraulicos',
						color: 'success',
					},
					{
						icon: 'maps_home_work',
						title: 'Fabricacion',
						text: 'Fabricacion de Elementos Mecanicos e Hidraulicos para diferentes sectores',
						color: 'error',
					},
					{
						icon: 'construction',
						title: 'Mecanizados',
						text: 'Servicios de Mecanizado en Torno y Fresa',
						color: 'warning',
					},
					{
						icon: 'attach_money',
						title: 'Ventas',
						text: 'Venta de Empaquetadura, Rodamientos, Barras Cromadas y Camisas Hidraulicas',
						color: '#FF8733',
					},
				],
			},
		};
	},
};
</script>
<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
